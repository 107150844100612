import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import SendIconSVG from '../../assets/send.inline.svg';

const ContactWrapper = styled.form`
  width: 90vw;
  margin: 4rem auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    width: 615px;
    height: 458px;
    font-weight: bold;
    margin: 0 13rem 0 0;
  }
`;

const TitleSection = styled.div`
  margin-left: calc(50vw - 50%);
  width: 100%;
  height: 5rem;
  font-size: ${({ theme }) => theme.font.size.superbig};
  ::after {
    position: absolute;
    content: '';
    width: 4.8rem;
    height: 0.4rem;
    display: block;
    background-color: ${({ theme }) => theme.font.color.accent};
    margin-top: -0.6rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    margin-left: calc(50vw - 45%);
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    margin-left: 0;
  }
`;

const InputSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 8.8rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    width: 70%;
    margin: 8.8rem auto;
    flex-direction: row;
    justify-content: space-between;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    width: 100%;
  }
`;

const NameAndBreedSection = styled.div`
  width: 80%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    width: 40%;
    margin: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    width: 25rem;
  }
`;

const EmailAndPhoneSection = styled.div`
  width: 80%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    width: 40%;
    margin: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    width: 25rem;
  }
`;

const MessageAndSendSection = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    width: 100%;
  }
`;
const SendButton = styled.button``;

const Label = styled.label`
  font-size: ${({ theme }) => theme.font.size.bigger};
  margin-bottom: 0.4rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    width: 50%;
  }
`;

const Input = styled.input`
  font-family: ${({ theme }) => theme.font.family.main};
  font-size: ${({ theme }) => theme.font.size.bigger};
  background-color: transparent;
  border: 0;
  border-bottom: 0.1rem solid ${({ theme }) => theme.inputUnderline};
  margin-bottom: 4.4rem;
  width: 100%;
  text-align: center;
  resize: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    width: 100%;
  }
`;
const InputArea = styled.input`
  font-family: ${({ theme }) => theme.font.family.main};
  font-size: ${({ theme }) => theme.font.size.bigger};
  background-color: transparent;
  border: 0;
  border-bottom: 0.1rem solid ${({ theme }) => theme.inputUnderline};
  margin-bottom: 4.4rem;
  width: 100%;
  text-align: center;
  resize: none;
`;

const SendIcon = styled(SendIconSVG)`
  fill: ${({ theme }) => theme.font.color.secondary};
  :hover {
    cursor: pointer;
    fill: red;
  }
`;

const SendIconWrap = styled.div`
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 5px;
  display: flex;
  height: 5rem;
  justify-content: center;
  width: 5rem;
`;

const query = graphql`
  {
    datoCmsContact {
      header
      name
      email
      dogBreed
      phone
      placeholdertext
    }
  }
`;

const ContactPage = () => {
  const data = useStaticQuery(query);
  const {
    header,
    name,
    email,
    dogBreed,
    phone,
    placeholdertext,
  } = data.datoCmsContact;

  return (
    <ContactWrapper
      action="https://getform.io/f/eebcc521-dde3-4993-9540-e882da21da79"
      method="POST"
    >
      <TitleSection>{header}</TitleSection>
      <InputSection>
        <NameAndBreedSection>
          <Label>{name}*</Label>
          <Input type="text" name="name" required />
          <Label>{dogBreed}*</Label>
          <Input type="text" name="dogBreed" required />
        </NameAndBreedSection>
        <EmailAndPhoneSection>
          <Label>{email}*</Label>
          <Input type="email" name="email" required />
          <Label>{phone}</Label>
          <Input type="tel" name="tel" />
        </EmailAndPhoneSection>
      </InputSection>
      <MessageAndSendSection>
        <InputArea type="text" name="message" placeholder={placeholdertext} />
        <SendButton type="submit">
          <SendIconWrap>
            <SendIcon />
          </SendIconWrap>
        </SendButton>
      </MessageAndSendSection>
    </ContactWrapper>
  );
};

export default ContactPage;
