import React from 'react';
import styled from 'styled-components';
import ContactForm from '../components/molecules/ContactForm';
import ContactData from '../components/molecules/ContactData';

const Wrapper = styled.section`
  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    margin: 0 auto;
    padding-bottom: 5rem;
    display: flex;
    align-content: center;
    justify-content: center;
    > form {
      animation-name: slideFromLeft;
      animation-duration: 1s;
    }
    > div {
      animation-name: slideFromRight;
      animation-duration: 1s;
    }
    @keyframes slideFromLeft {
      from {
        transform: translateX(-100vw);
      }
      to {
        transform: translateX(0);
      }
    }
    @keyframes slideFromRight {
      from {
        transform: translateX(100vw);
      }
      to {
        transform: translateX(0);
      }
    }
  }
`;

const ContactPage = () => (
  <Wrapper>
    <ContactForm />
    <ContactData />
  </Wrapper>
);

export default ContactPage;
