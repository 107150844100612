import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import SalonAddressInfo from './SalonAddressInfo';
import MarkerRed from '../../assets/marker-red.inline.svg';
import MapRed from '../../assets/map-red.inline.svg';
import MailRed from '../../assets/mail-red.inline.svg';
import PhoneRed from '../../assets/phone-red.inline.svg';
import SalonPhoneNumber from './SalonPhoneNumber';
import SalonOpenHours from './SalonOpenHoursList';
import WatchRed from '../../assets/watch-red.inline.svg';

const ContactWrapper = styled.div`
  margin: 8rem auto;
  * {
    font-family: ${({ theme }) => theme.font.family.main};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    display: flex;
    justify-content: center;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    flex-direction: column;
    margin: 0;
    font-weight: bold;
    ::before {
      position: absolute;
      content: '';
      width: 0.1rem;
      height: 44.8rem;
      display: block;
      background-color: ${({ theme }) => theme.inputUnderline};
      margin-top: -2rem;
    }
  }
`;

const StyledFlexWrap = styled.div``;

const StyledSalonText = styled.p`
  margin: 0 0 0.4rem 2rem;
  font-family: ${({ theme }) => theme.font.family.main};
  font-size: ${({ theme }) => theme.font.size.normal};
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: ${({ theme }) => theme.font.size.bigger};
  }
`;

const StyledMarker = styled(MarkerRed)`
  position: absolute;
  top: 0%;
  left: -3.2rem;
  fill: red;
`;

const StyledMap = styled(MapRed)`
  position: absolute;
  top: -30%;
  left: -3.2rem;
  fill: red;
`;

const StyledMail = styled(MailRed)`
  position: absolute;
  top: 0%;
  left: -3.2rem;
  fill: red;
`;
const StyledPhone = styled(PhoneRed)`
  position: absolute;
  top: -40%;
  left: -3.2rem;
  fill: red;
`;
const StyledWatch = styled(WatchRed)`
  position: absolute;
  top: 0%;
  left: -3.2rem;
  fill: red;
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.font.color.main};
`;

const StyledTextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  margin-bottom: 3rem;
  margin-left: 28vw;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    margin-left: 10rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    margin-left: 9rem;
  }
`;

const query = graphql`
  {
    datoCmsSaloninfo {
      info {
        ... on DatoCmsOpenhour {
          id
          maandag
          dinsdag
          woensdag
          donderdag
          vrijdag
          zaterdag
          zondag
        }
        ... on DatoCmsAddress {
          id
          first
          second
        }
        ... on DatoCmsPhoneNumber {
          id
          phoneNumber
        }
        ... on DatoCmsEmail {
          email
        }
        ... on DatoCmsSalonName {
          salonName
        }
        ... on DatoCmsMapLocation {
          mapLocation
        }
      }
    }
  }
`;

const ContactData = () => {
  const data = useStaticQuery(query);
  const salonInfo = data.datoCmsSaloninfo.info;

  const address = [salonInfo[0]];
  const phoneNumber = [salonInfo[2]];
  const daysOfWeek = [salonInfo[1]];
  const salonName = salonInfo[3].salonName;
  const salonEmail = salonInfo[4].email;
  const mapLocation = salonInfo[5].mapLocation;

  return (
    <ContactWrapper>
      <StyledFlexWrap>
        <StyledTextWrapper>
          <StyledLink href={mapLocation}>
            <StyledMarker />
            <SalonAddressInfo address={address} />
          </StyledLink>
        </StyledTextWrapper>
        <StyledTextWrapper>
          <StyledLink href={mapLocation}>
            <StyledMap />
            <StyledSalonText>{salonName}</StyledSalonText>
          </StyledLink>
        </StyledTextWrapper>
        <StyledTextWrapper>
          <StyledLink href={`mailto:${salonEmail}`}>
            <StyledMail />
            <StyledSalonText>{salonEmail}</StyledSalonText>
          </StyledLink>
        </StyledTextWrapper>
        <StyledTextWrapper>
          <StyledPhone />
          <SalonPhoneNumber phoneNumber={phoneNumber} />
        </StyledTextWrapper>
      </StyledFlexWrap>
      <StyledTextWrapper>
        <StyledWatch />
        <SalonOpenHours daysOfWeek={daysOfWeek} />
      </StyledTextWrapper>
    </ContactWrapper>
  );
};

export default ContactData;
